import { CampaignInfo } from "./CampaignInfo";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaPause, FaPlay } from "react-icons/fa";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_PAUSE,
} from "../../constants/campaignConstants";

export const CampaignOperation = ({
  campaign,
  loading,
  handleChangeStatusAll,
  options,
}: any) => {
  return (
    <div className="flex mt-4  flex-col border border-5 p-4 rounded-md hover:border-red-700">
      <div className="flex flex-row justify-end">
        {loading ? null : (
          <div className="flex gap-8">
            <RiDeleteBin5Fill
              title="delete all campaign"
              size="20px"
              onMouseOver={({ target }: any) => (target.style.color = "red")}
              onMouseOut={({ target }: any) => (target.style.color = "black")}
              onClick={() => handleChangeStatusAll(CAMPAIGN_STATUS_DELETED)}
            />

            <FaPause
              title="pause all campaign"
              size="20px"
              onMouseOver={({ target }: any) => (target.style.color = "orange")}
              onMouseOut={({ target }: any) => (target.style.color = "black")}
              onClick={() => handleChangeStatusAll(CAMPAIGN_STATUS_PAUSE)}
            />

            <FaPlay
              title="active all campaign"
              onMouseOver={({ target }: any) => (target.style.color = "green")}
              onMouseOut={({ target }: any) => (target.style.color = "black")}
              size="20px"
              onClick={() => handleChangeStatusAll(CAMPAIGN_STATUS_ACTIVE)}
            />
          </div>
        )}
      </div>
      <CampaignInfo campaign={campaign} options={options} />
    </div>
  );
};
