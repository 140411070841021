import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ShowMediaFile } from "../ShowMediaFile";
import { getVideoDurationFromVideoURL } from "../../../utils/fileUtils";
import { MdOutlineTimer } from "react-icons/md";
import { isNumber } from "@turf/turf";
import { isValidUrl } from "../../../utils/valueValidate";
import { getAWSUrlToUploadFile, saveFileOnAWS } from "../../../utils/awsUtils";
import {
  editEndDateWithStatus,
  getCampaignListByCampaignIds,
  getCompletedCampaignsByScreenId,
  updateCampaign,
} from "../../../actions/campaignAction";
import { MultipleFileUploader } from "../MultipleFileUploader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_COMPLETED,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_PAUSE,
  UPDATE_CAMPAIGN_RESET,
} from "../../../constants/campaignConstants";
import { Radio, Space, Tabs } from "antd";
import type { TabsProps } from "antd";
import { CloseButton, SaveButton } from "../Button";

interface MediaFile {
  url: string;
  type: string;
  file: any;
  awsURL: string;
  fileSize: number;
  fileType: string;
  videoDuration: number;
}

export function UpdateCampaignModel(props: any) {
  let { campaign, campaignIds = [], screen } = props;
  const dispatch = useDispatch<any>();
  const [campaignOption, setCampaignOption] = useState("Image/Video");
  const [selectedMedia, setSelectedMedia] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [status, setStatus] = useState<string>("");

  const [url, setUrl] = useState<any>("");
  const [campaignDuration, setCampaignDuration] = useState<any>("0");

  const [mediaFiles, setMediaFiles] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const campaignUpdate = useSelector((state: any) => state.campaignUpdate);
  const { loading, error, data } = campaignUpdate;

  const openErrorToast = (message: string) => {
    toast.error(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const openSuccessToast = (message: string) => {
    toast.success(message, {
      style: {
        marginTop: "50px",
      },
    });
  };

  const handelDiscard = () => {
    setMediaFiles(null);
    setSelectedMedia(null);
    setUrl("");
    setCampaignDuration("");
    setIsLoading(false);
    props.onClose();
  };

  const validateSelectedFile = (file: any) => {
    // const MIN_FILE_SIZE = 1024; // 1MB
    let mb = 1000; // mb
    const MAX_FILE_SIZE = mb * 1000 * 1024; // 5MB
    const fileExtension = file.type.split("/")[1];
    const fileSizeKiloBytes = file.size; // convert to kb
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      openErrorToast(
        "File size is greater than maximum limit. File size must be less then 50 MB "
      );
      return false;
    }
    if (
      !(
        fileExtension === "mp4" ||
        fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png"
      )
    ) {
      return false;
    }
    return true;
  };

  const handleFilesUploader = async (files: FileList) => {
    let file = Array.from(files)[0];
    if (validateSelectedFile(file)) {
      const url = URL.createObjectURL(file);
      let duration: any = 20;
      if (file.type.split("/")[0] != "image") {
        duration = await getVideoDurationFromVideoURL(url);
      }
      setMediaFiles({
        file: file,
        url,
        type: file.type,
        awsURL: "",
        fileSize: file.size,
        fileType: file.type,
        videoDuration: duration,
      });
    }
  };

  useEffect(() => {
    if (props?.isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [props?.isOpen]);

  useEffect(() => {
    if (error) {
      openErrorToast(error);
      dispatch({ type: UPDATE_CAMPAIGN_RESET });
      setIsLoading(false);
    }
    if (data) {
      if (screen) {
        dispatch(
          getCompletedCampaignsByScreenId(
            screen?.screenId,
            CAMPAIGN_STATUS_COMPLETED
          )
        );
      }
      openSuccessToast("Successfully change media");
      setIsLoading(false);
      if (campaignIds?.length > 0)
        dispatch(getCampaignListByCampaignIds(campaignIds));

      setTimeout(() => {
        handelDiscard();
      });
    }
  }, [error, data]);

  useEffect(() => {
    setStatus(campaign?.status);
  }, [campaign]);

  if (!props?.isOpen) {
    return null;
  }
  const handleAddCampaignOption = (checked: boolean) => {
    if (checked) {
      setCampaignOption("URL");
    } else {
      setCampaignOption("Image/Video");
    }
  };

  const createCampaignFromMedia = async () => {
    setIsLoading(true);
    let myData = mediaFiles;
    const aws = await getAWSUrlToUploadFile(myData.fileType);
    const successAWSUploadFile = await saveFileOnAWS(aws?.url, myData.file);
    myData.awsURL = aws?.awsURL;

    dispatch(
      updateCampaign({
        campaignId: campaign?._id,
        newVideoURL: myData?.awsURL,
        fileType: myData.fileType,
        duration:
          myData.fileType.split("/")[0] === "image"
            ? Number(campaignDuration)
            : Number(myData.videoDuration),
        fileSize: myData?.fileSize,
      })
    );
  };

  const createCampaignFromURL = () => {
    setIsLoading(true);
    dispatch(
      updateCampaign({
        campaignId: campaign?._id,
        newVideoURL: url,
        fileType: "url",
        duration: Number(campaignDuration),
        fileSize: 0,
        endDate,
      })
    );
  };

  const changeEndDateOrStatus = () => {
    setIsLoading(true);
    if (endDate || campaign.status != status)
      dispatch(
        editEndDateWithStatus({
          endDate: endDate ? new Date(endDate).toISOString() : campaign.endDate,
          status,
          campaignIds: [campaign._id],
        })
      );
    else openErrorToast("No need to save , data as old data");
  };

  const isImagePresent = () => {
    return mediaFiles?.fileType.split("/") == "image";
  };

  const validateForm = () => {
    if (campaignOption === "Image/Video" && mediaFiles === null) {
      openErrorToast("Please Select Media First!");
      return false;
    } else if (campaignOption === "URL" && !isValidUrl(url)) {
      openErrorToast("Please enter valid url");
      setUrl("");
      return false;
    } else if (
      mediaFiles?.fileType.split("/")[0] == "image" &&
      !campaignDuration
    ) {
      openErrorToast("Please inter duration for image campaign");
      return false;
    } else if (campaignOption === "URL" && !campaignDuration) {
      openErrorToast("Please set campaign duration in sec");
      return false;
    } else if (campaignOption === "URL" && !isNumber(campaignDuration)) {
      openErrorToast("Please Enter only number for campaign duration");
      setCampaignDuration("");
      return false;
    } else {
      return true;
    }
  };
  const handleNext = (e: any) => {
    if (validateForm()) {
      // console.log("form validated");
      if (url?.length > 0 && campaignOption === "URL") {
        createCampaignFromURL();
      } else {
        createCampaignFromMedia();
      }
    }
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Change Media only",
      children: (
        <div>
          <div className="flex flex-col justify-center">
            <h1 className="text-blue-600 text-xl">Update campaign Media</h1>
            {isLoading && (
              <h1 className="border border-1 bg-yellow-600 text-white text-lg px-8 py-2">
                Wait for some time file is saving....
              </h1>
            )}
          </div>
          <div>
            <div className="flex flex-row gap-2">
              <input
                placeholder="a"
                type="checkbox"
                id="url"
                checked={campaignOption === "URL" ? true : false}
                onChange={(e) => handleAddCampaignOption(e.target.checked)}
              />
              <label className="text-sm font-black" htmlFor="url">
                url
              </label>
            </div>
          </div>
          {campaignOption === "URL" ? (
            <div className="flex flex-col">
              <div className="flex flex-col">
                <h1>Media URL:</h1>
                <input
                  placeholder="Enter media url"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                />
              </div>
            </div>
          ) : mediaFiles ? (
            <div>
              <div className="flex fle-row justify-between">
                <p className="py-1">Uploaded media</p>
                <button
                  className=""
                  type="submit"
                  onClick={() => {
                    setMediaFiles(null);
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                <ShowMediaFile
                  url={mediaFiles.url || url}
                  mediaType={mediaFiles?.fileType?.split("/")[0] || "url"}
                />
              </div>
            </div>
          ) : (
            <div className="py-2">
              <h1 className="">Upload content</h1>
              <MultipleFileUploader handleFilesUploader={handleFilesUploader} />
              <h1 className="text-sm font-semibold text-red-700">{`Max file size less then 50 MB`}</h1>
            </div>
          )}

          {(campaignOption === "URL" ||
            mediaFiles?.fileType.split("/")[0] == "image") && (
            <div className="flex flex-col">
              {/* <h1 fontSize="md" color="#131D30" fontWeight="400" m="0">
                Duration{" "}
              </h1> */}
              <div className="py-2 flex items-center gap-2">
                <MdOutlineTimer fontSize="20px" />
                <h1 className="">(in seconds)</h1>
              </div>
              <input
                placeholder="Enter duration in sec."
                type="number"
                className="border border-gray-300 rounded-sm w-full h-10 text text-sm text-black-600 p-2"
                value={campaignDuration}
                onChange={(e) => setCampaignDuration(e.target.value)}
              />
            </div>
          )}
          <div className="flex flex-row justify-end pt-5 gap-4">
            <CloseButton onClick={handelDiscard} />
            <SaveButton onClick={handleNext} />
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Change End Date or status",
      children: (
        <div>
          <div className="flex flex-col gap-2 py-2">
            <h1>End Date & Time</h1>
            <input
              placeholder="h"
              className="border p-2 rounded"
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={new Date().toISOString().slice(0, -8)}
            />
          </div>
          <h1 className="py-2">Change Campaign Status</h1>
          <Radio.Group
            onChange={(e) => setStatus(e.target.value)}
            value={status}
          >
            <Space direction="vertical">
              <Radio value={CAMPAIGN_STATUS_ACTIVE}>Active</Radio>
              <Radio value={CAMPAIGN_STATUS_PAUSE}>Pause</Radio>
              <Radio value={CAMPAIGN_STATUS_COMPLETED}>Completed</Radio>
              <Radio value={CAMPAIGN_STATUS_DELETED}>Deleted</Radio>
            </Space>
          </Radio.Group>
          <div className="flex flex-row justify-end pt-5 gap-4">
            <CloseButton onClick={handelDiscard} />
            <SaveButton onClick={changeEndDateOrStatus} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10 ">
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-9/12 max-w-full relative overflow-auto max-h-auto no-scrollbar"
        style={{ height: "50vh", width: "40vw" }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />

        <div className="pt-20">
          <ToastContainer position="top-center" />
        </div>
      </div>
    </div>
  );
}
