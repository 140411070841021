import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCricketMatchesLists } from "../../actions/screenDataAction";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { Loading, Message } from "../../components";
import { useNavigate } from "react-router-dom";
import { CRICKET_TRIGGERS } from "../../constants/localStorageConstants";
import { Radio, Space } from "antd";

interface MatchPlayerTriggerInterface {
  playerName: string;
  trigger: string;
}

interface CricketTriggerInterface {
  matchId: string;
  startDate: string;
  startTime: string;
  triggers: MatchPlayerTriggerInterface[];
}

export const CricketTrigger = (props: any) => {
  const { additionalCost } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [matchId, setMatchId] = useState<string>("");
  const [cricketTriggers, setCricketTriggers] = useState<
    CricketTriggerInterface[]
  >([]);

  const [matchTriggersForOnePlayer, setMatchTriggersForOnePlayer] = useState<
    MatchPlayerTriggerInterface[]
  >([]);
  const [selectedPlayer, setSelectedPlayer] = useState<string>("");
  const [selectedHits, setSelectedHit] = useState<string>("");
  const [selectMatchTrigger, setSelectMatchTrigger] =
    useState<string>("hits 4");

  // console.log("cricketTriggers : ", cricketTriggers);
  //cricketMatches reducers
  const cricketMatchesListGet = useSelector(
    (state: any) => state.cricketMatchesListGet
  );
  const {
    loading: loadingMatches,
    error: errorMatches,
    matches,
  } = cricketMatchesListGet;

  const handleGetAdditionalCostForCricket = (
    cricketTriggers: CricketTriggerInterface[]
  ) => {
    let total = 0;
    let x = Number(Number(props?.totalCost * 0.1).toFixed(0));
    for (let data of cricketTriggers) {
      let count = data?.triggers?.length;
      total += x * count;
    }
    props.setAdditionalCostForCricket(total);
  };
  useEffect(() => {
    const res = JSON.parse(
      window.localStorage.getItem(CRICKET_TRIGGERS) || "[]"
    );
    setCricketTriggers(res);
  }, [navigate]);

  const saveCricketTriggersOnLocalStorage = (
    cricketTriggers: CricketTriggerInterface[]
  ) => {
    if (confirm("Do you really want to save ?")) {
      let arr = cricketTriggers?.filter(
        (data: CricketTriggerInterface) => data.triggers?.length > 0
      );
      window.localStorage.setItem(CRICKET_TRIGGERS, JSON.stringify(arr));
    }
  };

  const isMatchIdSelectedInCricketTriggers = (matchId: string) => {
    let res = cricketTriggers.some((data) => data?.matchId == matchId);
    // console.log("isMatchIdSeelcted : ", matchId, res);
    return res;
  };
  const handleRemoveMatchIdInCricketTriggers = (matchId: string) => {
    let arr = cricketTriggers;
    arr = arr.filter((data) => data.matchId != matchId);
    setCricketTriggers(arr);
    handleGetAdditionalCostForCricket(arr);
    // saveCricketTriggersOnLocalStorage(arr);
    setMatchTriggersForOnePlayer([]);
    setMatchId("");
    console.log("Remove successfully!");
  };
  const handleAddMatchIdInCricketTriggers = (matchId: string) => {
    setMatchId(matchId);
    if (isMatchIdSelectedInCricketTriggers(matchId)) {
      let res = cricketTriggers.find(
        (data: CricketTriggerInterface) => data?.matchId === matchId
      );
      let arr = res?.triggers || [];
      setMatchTriggersForOnePlayer(arr);
      setSelectedPlayer(arr?.[0]?.playerName);
    } else {
      setSelectedPlayer("");
      let arr = cricketTriggers;
      let matchDetails = matches.find((match: any) => match.matchId == matchId);
      // arr.push({
      //   matchId: matchId,
      //   startDate: matchDetails.startDate,
      //   startTime: matchDetails.startTime,
      //   triggers: [],
      // });
      setCricketTriggers([
        {
          matchId: matchId,
          startDate: matchDetails.startDate,
          startTime: matchDetails.startTime,
          triggers: [],
        },
      ]);
      setMatchTriggersForOnePlayer([]);
    }
    // saveCricketTriggersOnLocalStorage(cricketTriggers);
  };

  // match Triggers For One Player

  const removeDuplicates = (arr: MatchPlayerTriggerInterface[]) => {
    return arr.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (t) => t.playerName === obj.playerName && t.trigger === obj.trigger
        )
    );
  };

  const handleSaveMatchTriggersForPlayers = () => {
    if (matchId == undefined || matchId === null || matchId.length === 0) {
      alert("please select matchId");
      return;
    } else if (matchTriggersForOnePlayer.length === 0) {
      alert(
        "Please select player first and then select triggers for that player"
      );
    } else {
      if (isMatchIdSelectedInCricketTriggers(matchId)) {
        let arr = cricketTriggers;
        for (let data of cricketTriggers) {
          if (data?.matchId === matchId) {
            let x = [...data.triggers, ...matchTriggersForOnePlayer];
            // make set of x and  then save the x value in data.triggers
            data.triggers = removeDuplicates(x);
            setMatchTriggersForOnePlayer([]);
          }
        }
        // saveCricketTriggersOnLocalStorage(arr);
        setCricketTriggers(arr);
        handleGetAdditionalCostForCricket(arr);
        setMatchId("");
        setSelectedPlayer("");
        alert("saved!");
      }
    }
  };

  const handleSelectMatchTrigger = (e: any) => {
    const value = e.target.value;
    setSelectMatchTrigger(value);
    setMatchTriggersForOnePlayer([
      { playerName: selectedPlayer, trigger: value },
    ]);
  };

  const handleAddMatchTriggersForEachPlayer = (
    playerName: string,
    trigger: string,
    option: boolean
  ) => {
    if (playerName.length === 0) {
      alert("Please select player name first!");
      return;
    }
    let arr = matchTriggersForOnePlayer;
    if (option) {
      if (
        arr.find(
          (data: MatchPlayerTriggerInterface) =>
            data.playerName === playerName && data.trigger === trigger
        )
      ) {
        return;
      } else {
        arr.push({ playerName, trigger });
      }
    } else {
      arr = arr.filter(
        (data) => data?.trigger !== trigger && data.playerName !== playerName
      );
    }
    setMatchTriggersForOnePlayer(arr);
    setSelectedHit(trigger);
  };

  const isSelectedMatchTrigger = (playerName: string, trigger: string) => {
    let res = matchTriggersForOnePlayer.some(
      (data: any) => data.playerName === playerName && data.trigger === trigger
    );
    return res;
  };

  // select plater
  const handleSelectPlayer = (player: string) => {
    if (matchId == undefined || matchId === null) {
      alert("please select any one of the matches");
      return;
    } else {
      if (player.length === 0) {
        alert("Please select player name");
        return;
      }
      setSelectedPlayer(player);
    }
  };

  useEffect(() => {
    if (!matches) {
      dispatch(getCricketMatchesLists());
    }
  }, [dispatch, matches]);

  return (
    <div className="py-5">
      {loadingMatches ? (
        <Loading />
      ) : errorMatches ? (
        <Message message="ERROR MESSAGE" content={errorMatches} />
      ) : (
        <div className="grid grid-cols-12">
          {/* <div className="col-span-8 border border-black my-5">
            <div className="border border-b-1 py-3 px-5">
              <h1 className="text-lg font-bold">Cricket API</h1>
            </div>
            <div className="py-3">
              <div className="px-5 flex flex-row gap-20">
                <h1 className="text-lg font-semibold">Select Matches</h1>
              </div>
              <table className="w-full">
                <thead className="bg-gray-200">
                  <tr className="">
                    <th className="col-span-1">
                      <h1 className="text-base text-center">Date</h1>
                    </th>
                    <th className="col-span-4">
                      <h1 className="text-base text-center">Match Details</h1>
                    </th>
                    <th className="col-span-1">
                      <h1 className="text-base text-center">Action</h1>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {matches?.map((match: any, i: any) => (
                    <tr key={i} className="border-b">
                      <td className="col-span-1">
                        <h1 className="text-sm text-center">
                          {match.startDate}
                        </h1>
                      </td>
                      <td className="col-span-4">
                        <div className="">
                          <h1 className="text-sm text-center">
                            {match.team1} Vs {match.team2},{" "}
                            {match.matchDescription}
                          </h1>
                          <h1 className="text-xs text-center">
                            {match.ground}, {match.city}
                          </h1>
                          <h1 className="text-xs text-center">
                            Match starts as {match.startTime}
                          </h1>
                        </div>
                      </td>
                      <td className="col-span-1">
                        <h1
                          className="text-base text-center cursor-pointer"
                          onClick={() =>
                            handleRemoveMatchIdInCricketTriggers(match?.matchId)
                          }
                        >
                          {isMatchIdSelectedInCricketTriggers(match?.matchId)
                            ? "Remove"
                            : "Select"}
                        </h1>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div></div>
            </div>
          </div> */}
          <div className="col-span-8 border border-black my-5">
            <div className="flex border border-b-1 py-3 px-5">
              <h1 className="text-lg font-bold pr-4">Cricket API</h1>
              <select
                className="border w-64 h-8 "
                onChange={(e) =>
                  handleAddMatchIdInCricketTriggers(e.target.value)
                }
                value={matchId}
              >
                <option label="---Select match---" value="" />
                {matches?.map((match: any, i: any) => (
                  <option
                    value={match?.matchId}
                    label={`${match.team1} Vs ${match.team2}, ${match.matchDescription}`}
                    key={i}
                  />
                ))}
              </select>
            </div>
            <div className="px-5 py-3">
              <div className="flex flex-row gap-20">
                <h1 className="text-lg font-semibold">
                  Play ads during matches when
                </h1>
                <select
                  className="border rounded w-32 h-8"
                  onChange={(e) => handleSelectPlayer(e.target.value)}
                  value={selectedPlayer}
                >
                  <option label="---Select Player---" value="" />
                  <option label="Virat Koli" value="Virat Koli" />
                  <option label="Rohit sarma" value="Rohit sarma" />{" "}
                  <option label="Dhoni" value="Dhoni" />{" "}
                  <option label="Hardik Pandiya" value="Hardik Pandiya" />
                </select>
              </div>
              <div>
                <Radio.Group
                  onChange={handleSelectMatchTrigger}
                  value={selectMatchTrigger}
                >
                  <Space direction="vertical">
                    <Radio value={"hits 6"}>hits 6</Radio>
                    <Radio value={"hits 4"}>hits 4</Radio>
                    <Radio value={"score 50"}>score 50</Radio>
                    <Radio value={"takes catch"}>takes catch</Radio>
                    <Radio value={"bowls out"}>bowls out</Radio>
                    <Radio value={"batting duration"}>batting duration</Radio>
                    <Radio value={"bowling duration"}>bowling duration</Radio>
                  </Space>
                </Radio.Group>
              </div>
              <div>
                <div className="flex flex-row pt-5 justify-between">
                  <div className="flex flex-row items-center gap-5 px-8">
                    <div className="basis-3/4">
                      <button
                        title="Save this match triggers"
                        type="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                      >
                        <h1
                          className="text-base text-white"
                          onClick={handleSaveMatchTriggersForPlayers}
                        >
                          Save
                        </h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      <RiDeleteBin5Fill
                        title="remove this selected match trigger"
                        color="red"
                        size="24px"
                        onClick={() =>
                          handleRemoveMatchIdInCricketTriggers(matchId)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-12">
        <div className="col-span-8 flex flex-row justify-between gap-16">
          <h1 className="font-bold">
            Kindly re-confirm the additional budget of INR {additionalCost} for
            triggering your ads
          </h1>
          <div className="flex flex-row gap-4 pr-14">
            <div className="basis-1/4 flex flex-row gap-3 items-center">
              <input
                title="check"
                type="checkbox"
                id="check"
                name="check"
                className="form-checkbox scale-150"
              />
            </div>
            <div className="basis-1/2 flex items-center">
              <button
                type="button"
                title="button"
                className="p-1 w-20 h-8 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
              >
                <h1
                  className="text-base text-white"
                  onClick={() =>
                    saveCricketTriggersOnLocalStorage(cricketTriggers)
                  }
                >
                  Save
                </h1>
              </button>
            </div>
            <div className="basis-1/4 flex justify-center items-center">
              <RiDeleteBin5Fill color="red" size="24px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
