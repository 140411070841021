import { MdCheck, MdClose } from "react-icons/md";
import {
  convertDataTimeToLocale,
  getNumberOfDaysBetweenTwoDates,
} from "../../utils/dateAndTimeUtils";
import { PleaButtons } from "./PleaButtons";
import { CreativesListModal } from "../../components/common/popup/CreativesListModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCampaign,
  updateCreativeForTrigger,
} from "../../actions/campaignAction";
import { CombButtons } from "./CombButtons";

export const CampaignPleaDetailsTable = (props: any) => {
  const { isTriggers } = props;
  const dispatch = useDispatch<any>();
  const { campaigns, allScreens, userInfo, detailsView } = props;

  const [selectedCampaignId, setSelectedCampaignId] = useState<any>(null);
  const [mediaFiles, setMediaFiles] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenForTrigger, setIsOpenForTrigger] = useState<boolean>(false);

  const creativesList = useSelector((state: any) => state.creativesList);
  const { loading, error, data } = creativesList;

  const handleCloseCreativeModel = () => {
    setIsOpen(false);
  };

  const handleCloseCreativeModelForTrigger = () => {
    setIsOpenForTrigger(false);
  };

  const handleCampaignSelection = ({ id, trueFalse }: any) => {
    setSelectedCampaignId(id);
    setIsOpen(true);
  };

  const handleCampaignSelectionForTrigger = ({ id, trueFalse }: any) => {
    if (confirm("Do you want to save creative for trigger ?")) {
      setSelectedCampaignId(id);
      setIsOpenForTrigger(true);
    }
  };

  const handleSelectAndSave = (data: any) => {
    dispatch(
      updateCampaign({
        campaignId: selectedCampaignId,
        newVideoURL: data[0]?.videoURL,
        fileType: data[0].extension,
        duration:
          data[0].creativeType === "image"
            ? Number(15)
            : Number(data[0].duration),
        fileSize: data[0]?.fileSize,
      })
    );
  };

  const handleSelectAndSaveCreativeForTrigger = (data: any) => {
    dispatch(
      updateCreativeForTrigger({
        campaignId: selectedCampaignId,
        newVideoURL: data[0]?.videoURL,
        fileType: data[0].extension,
        duration:
          data[0].creativeType === "image"
            ? Number(15)
            : Number(data[0].duration),
        fileSize: data[0]?.fileSize,
      })
    );
    console.log("data : ", data);
  };

  return (
    <div className="py-5">
      <CreativesListModal
        isOpen={isOpen}
        onClose={handleCloseCreativeModel}
        creativeList={data}
        mediaFiles={mediaFiles}
        setMediaFiles={handleSelectAndSave}
      />
      <CreativesListModal
        isOpen={isOpenForTrigger}
        onClose={handleCloseCreativeModelForTrigger}
        creativeList={data}
        mediaFiles={mediaFiles}
        setMediaFiles={handleSelectAndSaveCreativeForTrigger}
      />
      <CombButtons
        userInfo={userInfo}
        detailsView={detailsView}
        campaigns={campaigns}
        handleSendPleaRequest={props?.handleSendPleaRequest}
        handleSendPleaResponse={props?.handleSendPleaResponse}
      />
      <div className="overflow-scroll no-scrollbar">
        <table className="table-fixed w-full">
          <thead className="">
            <tr className="border bg-gray-100">
              <th className="w-40 border-x p-1">Media Owner</th>
              <th className="w-32 border-x p-1">Media Type</th>
              <th className="w-72 border-x p-1">Campaign Status</th>
              <th className="w-44 border-x p-1">Screen Name</th>
              <th className="w-44 border-x p-1">Screen Location</th>
              <th className="w-32 border-x p-1">Size</th>
              <th className="w-32 border-x p-1">Resolution</th>
              <th className="w-32 border-x p-1">Duration</th>
              <th className="w-40 border-x p-1">Activation Type</th>
              <th className="w-44 border-x p-1">Time for Ad Display</th>
              <th className="w-32 border-x p-1">Total Slots</th>
              <th className="w-32 border-x p-1">Cost Per Slot</th>
              <th className="w-32 border-x p-1">Total Value</th>
              {/* <th className="w-64 border-x p-1">Upload Creative</th> */}
            </tr>
          </thead>
          <tbody className="">
            {campaigns
              ?.filter(
                (ca: any) =>
                  ca.campaignWithMultipleMediasWithMultipleScreens ===
                  detailsView
              )
              ?.map((c: any, i: any) => (
                <tr
                  key={i}
                  className={`border 
                    ${c.status === "PleaRequestBudgetSent" && "bg-purple-50"}
                    ${
                      c.status === "PleaRequestBudgetAccepted" && "bg-purple-50"
                    }
                    ${
                      c.status === "PleaRequestBudgetRejected" && "bg-purple-50"
                    }
                    ${
                      c.status === "PleaRequestScreenApprovalSent" &&
                      "bg-blue-50"
                    }
                    ${
                      c.status === "PleaRequestScreenApprovalAccepted" &&
                      "bg-blue-50"
                    }
                    ${
                      c.status === "PleaRequestScreenApprovalRejected" &&
                      "bg-blue-50"
                    }
                    ${
                      c.status === "PleaRequestFinalApprovalSent" &&
                      "bg-violet-100"
                    }
                    ${
                      c.status === "PleaRequestFinalApprovalAccepted" &&
                      "bg-violet-100"
                    }
                    ${
                      c.status === "PleaRequestFinalApprovalRejected" &&
                      "bg-violet-100"
                    }
                  `}
                >
                  <td className="border-x p-2 text-lg font-bold">
                    {
                      allScreens
                        .filter((s: any) => s._id === c.screen)[0]
                        .masterEmail?.split("@")
                        .splice(0)[0]
                        .split(".")
                        .splice(0)[0]
                    }
                  </td>
                  <td className="border-x p-2">
                    {allScreens.filter((s: any) => s._id === c.screen)[0]
                      .integrationStatus
                      ? "True"
                      : "Connected"}
                  </td>
                  <td className="border-x p-2 truncate">
                    <div className="flex justify-between">
                      <h1
                        className={`${
                          c.status === "PleaRequestBudgetSent"
                            ? "text-purple-400"
                            : c.status === "PleaRequestBudgetAccepted"
                            ? "text-blue-400"
                            : c.status === "PleaRequestBudgetRejected"
                            ? "text-red-400"
                            : c.status === "PleaRequestScreenApprovalSent"
                            ? "text-purple-800"
                            : c.status === "PleaRequestScreenApprovalAccepted"
                            ? "text-blue-800"
                            : c.status === "PleaRequestScreenApprovalRejected"
                            ? "text-red-800"
                            : c.status === "PleaRequestFinalApprovalSent"
                            ? "text-purple-800"
                            : c.status === "PleaRequestFinalApprovalAccepted"
                            ? "text-blue-800"
                            : c.status === "PleaRequestFinalApprovalRejected"
                            ? "text-red-800"
                            : ""
                        }`}
                      >
                        {c.status === "PleaRequestBudgetSent"
                          ? "Budget Approval Pending"
                          : c.status === "PleaRequestBudgetAccepted"
                          ? "Budget Approved"
                          : c.status === "PleaRequestBudgetRejected"
                          ? "Budget Rejected"
                          : c.status === "PleaRequestScreenApprovalSent"
                          ? "Screen Approval Pending"
                          : c.status === "PleaRequestScreenApprovalAccepted"
                          ? "Screen Approved"
                          : c.status === "PleaRequestScreenApprovalRejected"
                          ? "Screen Rejected"
                          : c.status === "PleaRequestFinalApprovalSent"
                          ? "Final Aprroval Pending"
                          : c.status === "PleaRequestFinalApprovalAccepted"
                          ? "Final Approved"
                          : c.status === "PleaRequestFinalApprovalRejected"
                          ? "Final Rejected"
                          : c.status}
                      </h1>
                      <PleaButtons
                        loading={props?.loading}
                        userInfo={userInfo}
                        status={c.status}
                        campaign={c}
                        handleSendPleaRequest={props?.handleSendPleaRequest}
                        handleSendPleaResponse={props?.handleSendPleaResponse}
                        handleCampaignSelection={handleCampaignSelection}
                        handleCampaignSelectionForTrigger={
                          handleCampaignSelectionForTrigger
                        }
                        isTriggers={isTriggers}
                      />
                    </div>
                  </td>
                  <td className="border-x p-2 truncate">
                    {
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .screenName
                    }
                  </td>
                  <td className="border-x p-2 truncate">
                    {`${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .location.address
                    }, ${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .location.city
                    }`}
                  </td>
                  <td className="border-x p-2 truncate">
                    {`${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .screenLength
                    } X ${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .screenWidth
                    }`}
                  </td>
                  <td className="border-x p-2 truncate">
                    {
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .screenResolution
                    }
                  </td>
                  <td className="border-x p-2 truncate">
                    {getNumberOfDaysBetweenTwoDates(c.startDate, c.endDate)}
                  </td>
                  <td>
                    <div className="border-b p-2 truncate">
                      {c.campaignType} Loop
                    </div>
                    <div className="border-t p-2 truncate">
                      {c.isTriggers ? "Triggers Applied" : "No Triggers"}
                    </div>
                  </td>
                  <td className="border-x p-2 truncate">
                    {`${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .operationalDuration.onTime
                    } - ${
                      allScreens.filter((s: any) => s._id === c.screen)[0]
                        .operationalDuration.offTime
                    }`}
                  </td>
                  <td className="border-x p-2 truncate">
                    {c.totalSlotBooked.toFixed(0)}
                  </td>
                  <td className="border-x p-2 truncate">
                    {c.rentPerSlot.toFixed(0)}
                  </td>
                  <td className="border-x p-2 truncate">
                    {c.totalAmount.toFixed(0)}
                  </td>
                  {/* {c.status === "PleaRequestScreenApprovalAccepted" && (
                  <td
                    className="border-x cursor-pointer hover:bg-yellow-300 p-2"
                    onClick={() => {
                      setSelectedCampaignId(c?._id);
                      setIsOpen(true);
                    }}
                  >
                    Upload media
                  </td>
                  )} */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
