import { message } from "antd";
import { getCampaignsLog } from "../../actions/campaignAction";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as XLSX from "sheetjs-style";
import { CAMPAIGN_LOG_BY_CAMPAIGN_IDS_RESET } from "../../constants/campaignConstants";
import { convertDataTimeToLocale } from "../../utils/dateAndTimeUtils";

export const CampaignLogReport = ({ campaign, startDate, endDate }: any) => {
  const myCampaign = JSON.parse(campaign || `{}`);
  const screenId = myCampaign?.screenId || "";
  const campaignId = myCampaign?._id || "";
  console.log("campaign ", myCampaign);

  const dispatch = useDispatch<any>();
  const [campaignLog, setCampaignLog] = useState<any>([]);

  const campaignsLog = useSelector((state: any) => state.campaignsLog);
  const {
    loading: loadingCampaignLog,
    error: errorCampaignLog,
    success: successCampaignLog,
    data: campaignLogData,
  } = campaignsLog;

  const getData = (campaign: any, index: number) => {
    return [
      index,
      convertDataTimeToLocale(campaign?.time),
      campaign.screenStatus,
      campaign.screenMac,
      campaign.screenDeviceId,
      campaign?.screenIp,
      campaign?.screenDisplay,
    ];
  };

  const data = [
    [
      {
        v: "PROOH.AI ",
        s: {
          font: { bold: true, sz: 48 },
          border: {
            style: "thick",
            color: "000000",
          },
        },
      },
    ],
    [
      {
        v: `Campaign Name: ${myCampaign?.campaignName} `,
        s: {
          font: {
            name: "arial",
          },

          border: {
            style: "thin",
            color: "000000",
          },
        },
      },
      {
        v: `Brand: ${myCampaign?.brandName}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: "No. of Creative: 1",
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
    ],
    [
      {
        v: `Start Date: ${convertDataTimeToLocale(myCampaign?.startDate)}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: `End Date: ${convertDataTimeToLocale(myCampaign?.endDate)}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: `No. of Days: ${myCampaign?.campaignBookedForDays}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
    ],
    [
      {
        v: `Screen Name: ${myCampaign?.screenName}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: `Log Generated at: ${convertDataTimeToLocale(new Date())}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: "Campaign Type: Regular",
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
    ],
    [
      {
        v: `Total slots assigned: ${myCampaign?.totalSlotBooked}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: `Total slots played: ${myCampaign?.totalSlotsPlayed}`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
      {
        v: `Creative duration: ${myCampaign?.duration} Sec.`,
        s: {
          font: {
            name: "arial",
          },

          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
          },
        },
      },
    ],
    [],
    [
      {
        v: "S.N.",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Time stamp",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Screen Status",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Screen Mac",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Screen DeviceId",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Screen Ip",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
      {
        v: "Screen Display",
        s: {
          font: { bold: true, sz: 16 },
          fill: { fgColor: { rgb: "#C7C8CC" } },
        },
      },
    ],
    [],
    ...campaignLog?.map((campaign: any, index: number) =>
      getData(campaign, index + 1)
    ),
  ];

  const validate = () => {
    if (screenId == "" || campaignId == "") {
      message.error("Please select campaign");
      return false;
    } else if (startDate == "" || endDate === "") {
      message.error("Start date or end date messing ");
      return false;
    } else {
      return true;
    }
  };

  // Function to generate and download the .xlsx file
  const downloadExcel = useCallback(() => {
    console.log("downloadExcel called!");
    // Creating the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Adding some column widths (optional)
    worksheet["!cols"] = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 100 },
    ];

    worksheet["!rows"] = [
      { hpx: 50 }, // Height for the first row
      { hpx: 20 }, // Height for the second row
      { hpx: 20 }, // Height for the third row
      { hpx: 20 }, // Height for the fourth row
      { hpx: 20 }, // Height for the fifth row (empty)
      { hpx: 20 }, // Height for the header row
      { hpx: 20 }, // Height for the header row
    ];

    // Creating a new workbook and adding the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Campaign Report");

    // Writing and downloading the Excel file
    XLSX.writeFile(workbook, `${myCampaign?.campaignName}.xlsx`);
  }, [campaignLog]);

  const handleClick = () => {
    if (confirm("Do you really want to download logs?") && validate())
      dispatch(getCampaignsLog({ screenId, campaignId, startDate, endDate }));
  };

  useEffect(() => {
    if (campaignLogData?.length > 0) {
      setCampaignLog(campaignLogData);
      downloadExcel();
    } else {
      if (campaignLogData?.length === 0) {
        message.error("No Data found to download report");
        dispatch({ type: CAMPAIGN_LOG_BY_CAMPAIGN_IDS_RESET });
        setCampaignLog([]);
      }
    }
  }, [campaignLogData]);

  return (
    <div className="py-4">
      <button
        className="border border-1 py-2 px-4 rounded-lg bg-blue-400 text-white hover:bg-blue-500 w-40 text-center"
        onClick={handleClick}
        disabled={loadingCampaignLog}
      >
        {loadingCampaignLog ? "Loading ..." : "Download Excel"}
      </button>
    </div>
  );
};
