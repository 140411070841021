import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FinalSummaryTable, Loading, Message } from "../../components";
import { FaQuestion } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import sun1 from "../../assets/sun1.png";
import rain1 from "../../assets/rain1.png";
import cloud1 from "../../assets/cloud1.png";
import icecube1 from "../../assets/icecube1.png";
import speedometer1 from "../../assets/speedometer1.png";
import { useDispatch } from "react-redux";
import {
  getCricketMatchesLists,
  getPricingDetailsTableData,
} from "../../actions/screenDataAction";
import { useSelector } from "react-redux";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { CricketTrigger } from "./CricketTrigger";
import {
  AIR_QUALITY_GREATER_THAN,
  AIR_QUALITY_LESS_THAN,
  RAIN_FORECAST,
  TEMPERATURE_GREATER_THAN,
  TEMPERATURE_LESS_THAN,
  WIND_SPEED_GREATER_THAN,
  WIND_SPEED_LESS_THAN,
} from "../../constants/cricketTriggerConstants";
import {
  AUDIENCES_FILTER_OPTIONS,
  WEATHER_TRIGGERS,
  FILTERED_SCREENS,
  PRICING_DATA,
} from "../../constants/localStorageConstants";
import { message, Radio } from "antd";
import { NewFinalResultTable } from "../../components/common/tables/NewFinalResultTable";
import { ShowSelectedScreensModel } from "../../components/common/popup/ShowSelectedScreensModel";
import {
  getDataFromLocalStorage,
  saveDataOnLocalStorage,
} from "../../utils/localStorageUtils";

interface Trigger {
  triggerType: string;
  value: string;
}

export const FinalCampaignSummary = ({ data }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [temperature, setTemperature] = useState<any>("");
  const [coldTemperature, setColdTemperature] = useState<any>("");
  const [airQuality, setAirQuality] = useState<any>("");
  const [windSpeedType, setWindSpeedType] = useState<string>("G");
  const [windSpeed, setWindSpeed] = useState<any>("");
  const [airQualityType, setAirQualityType] = useState<string>("G");
  const [rainForCast, setRainForCast] = useState<string>("Y");
  const [weatherTriggers, setWeatherTriggers] = useState<Trigger[]>([]);
  const [selectedTrigger, setSelectedTrigger] =
    useState<string>("Weather trigger");
  const [totalCost, setTotalCost] = useState<number>(0);
  const [sumData, setSumData] = useState<any>({});
  const [netTotal, setNetTotal] = useState<number>(0);
  const [additionalCost, setAdditionalCost] = useState<number>(0);
  const [additionalCostForCricket, setAdditionalCostForCricket] =
    useState<number>(0);

  // FILTER SCREENS AGAIN IN FINAL SUMMARY PAGE
  const [filteredScreens, setFilteredScreens] = useState<any[]>([]);
  const [pricingData, setPricingData] = useState<any>({});
  const [allScreens, setAllScreens] = useState<any[]>([]);

  const [showFinalResults, setShowFinalResults] = useState<any>(false);
  const [showTriggers, setShowTriggers] = useState<any>(false);
  const [isOpenModel, setIsOpenModel] = useState<boolean>(false);
  // console.log("pricingData : ", pricingData);

  const campaignType: string = location.state.campaignDetails.campaignType;

  const pricingDetailsDataGet = useSelector(
    (state: any) => state.pricingDetailsDataGet
  );
  const {
    loading: loadingPricing,
    error: errorPricing,
    data: myPricingData,
  } = pricingDetailsDataGet;

  //cricketMatches reducers
  const cricketMatchesListGet = useSelector(
    (state: any) => state.cricketMatchesListGet
  );
  const {
    loading: loadingMatches,
    error: errorMatches,
    matches,
  } = cricketMatchesListGet;

  const finalSummaryDataGet = useSelector(
    (state: any) => state.finalSummaryDataGet
  );
  const {
    loading: loadingFinalSummary,
    error: errorFinalSummary,
    data: finalSummaryData,
  } = finalSummaryDataGet;

  // console.log("finalSummaryData", finalSummaryData);

  const handleSetFilteredScreen = (screenName: string) => {
    // console.log(" handleSetFilteredScreen screenName : ", screenName);
    if (
      filteredScreens.some((screen: any) => screen.screenName === screenName)
    ) {
      console.log("remove screen!");
      let arr = filteredScreens.filter(
        (screen: any) => screen.screenName !== screenName
      );
      message.success("Screen unselected!");
      setFilteredScreens(arr);
    } else {
      console.log("add screen!");
      let screen = allScreens?.find(
        (screen: any) => screen.screenName === screenName
      );
      if (screen != undefined) {
        setFilteredScreens((pre: any) => [...pre, screen]);
        message.success("Screen added!");
      } else {
        message.warning("This screen can't added!");
      }
    }
  };

  const handleMultipleFilteredScreen = (screenNames: string[]) => {
    // Create a set of screen names for quick lookup
    const screenNamesSet = new Set(screenNames);

    // Separate screens into those to be removed and those to be added
    const screensToRemove = filteredScreens.filter((screen: any) =>
      screenNamesSet.has(screen.screenName)
    );

    const screensToAdd = screenNames.filter(
      (screenName: string) =>
        !filteredScreens.some((screen: any) => screen.screenName === screenName)
    );

    // Remove screens
    if (screensToRemove.length > 0) {
      const updatedFilteredScreens = filteredScreens.filter(
        (screen: any) => !screenNamesSet.has(screen.screenName)
      );
      setFilteredScreens(updatedFilteredScreens);
      message.success("Screens unselected!");
    }

    // Add new screens
    if (screensToAdd.length > 0) {
      const screensToAddDetails = allScreens?.filter((screen: any) =>
        screensToAdd.includes(screen.screenName)
      );

      if (screensToAddDetails.length > 0) {
        setFilteredScreens((prev: any) => [...prev, ...screensToAddDetails]);
        message.success("Screens added!");
      } else {
        message.warning("Some screens can't be added!");
      }
    }
  };

  const isScreenSelected = (screenName: string) => {
    if (screenName == "") return false;
    let res = filteredScreens?.some(
      (screen: any) => screen?.screenName === screenName
    );
    return res;
  };

  // weather triggers helper function
  const isAddedWeatherTrigger = (triggerType: string) => {
    let res = weatherTriggers.some(
      (data: Trigger) => data.triggerType === triggerType
    );
    return res;
  };

  const handleAddTriggersOptionOnLocalStorage = (data: Trigger[]) => {
    saveDataOnLocalStorage(WEATHER_TRIGGERS, data);
  };

  const weatherTriggerRemove = (trigger: Trigger) => {
    let arr = weatherTriggers;
    arr = arr.filter(
      (data: Trigger) => data.triggerType !== trigger.triggerType
    );
    setWeatherTriggers(arr);
    let x = Number(Number(totalCost * 0.1).toFixed(0));
    // setNetTotal((pre: number) => pre - x);
    setAdditionalCost((pre: number) => pre - x);
    // alert("Removed weather trigger!");
  };
  const addWeatherTriggers = (trigger: Trigger) => {
    // console.log("addTrigger called! : ", trigger);
    if (trigger?.value?.length === 0) {
      alert(`Please add value for add ${trigger.triggerType} triggures`);
      return;
    }
    let arr = weatherTriggers;
    setWeatherTriggers([trigger]);
    let x = Number(Number(totalCost * 0.1).toFixed(0));
    // setNetTotal((pre: number) => x + pre);
    setAdditionalCost((pre: number) => x + pre);

    // if (isAddedWeatherTrigger(trigger.triggerType)) {
    //   arr = arr.filter(
    //     (data: Trigger) => data.triggerType != trigger.triggerType
    //   );
    //   arr.push(trigger);
    //   setWeatherTriggers(arr);
    // } else {
    //   arr.push(trigger);
    //   setWeatherTriggers(arr);
    //   let x = Number(Number(totalCost * 0.1).toFixed(0));
    //   // setNetTotal((pre: number) => x + pre);
    //   setAdditionalCost((pre: number) => x + pre);
    // }
    // alert("Weather trigger saved!");
    handleAddTriggersOptionOnLocalStorage(arr);
  };

  const handleSetPricingData = () => {
    let x = getDataFromLocalStorage(PRICING_DATA);
    let y = getDataFromLocalStorage(FILTERED_SCREENS);
    let pricingData1 = x || {};
    let filterScreenData = y || [];
    setFilteredScreens(filterScreenData);
    setAllScreens(filterScreenData);
    setPricingData(pricingData1);
  };

  useEffect(() => {
    handleSetPricingData();
  }, []);

  useEffect(() => {
    if (myPricingData !== undefined) {
      setPricingData(myPricingData);
      saveDataOnLocalStorage(PRICING_DATA, myPricingData);
    }
  }, [myPricingData]);

  useEffect(() => {
    // console.log("filter screens updateded", filteredScreens);
    if (filteredScreens?.length > 0) {
      dispatch(
        getPricingDetailsTableData({
          screenIds: filteredScreens?.map((s: any) => s.screenId),
          selectedList: getDataFromLocalStorage(AUDIENCES_FILTER_OPTIONS) || [],
        })
      );
    }
  }, [dispatch, filteredScreens]);

  useEffect(() => {
    let x =
      Number(totalCost) +
      Number(additionalCost) +
      Number(additionalCostForCricket);
    setNetTotal(x);
  }, [totalCost, additionalCost, additionalCostForCricket]);

  useEffect(() => {
    if (!matches) {
      dispatch(getCricketMatchesLists());
    }
  }, [dispatch, matches]);

  const handleClickGetEstimate = () => {
    saveDataOnLocalStorage(FILTERED_SCREENS, filteredScreens);
    saveDataOnLocalStorage(PRICING_DATA, pricingData);
    // navigate("/create-campaign/upload-creatives");
  };

  const handleCloseModel = () => {
    setIsOpenModel(false);
  };

  function handleSelectTrigger(e: any) {
    setSelectedTrigger(e.target.value);
  }

  return (
    <div className="mt-6 w-full h-full pb-5">
      <div className="pt-10">
        <ShowSelectedScreensModel
          isOpen={isOpenModel}
          onClose={handleCloseModel}
        />
        <h1 className="text-3xl font-bold">Final Summary</h1>
        <p className="text-sm">
          This shall help you decide Cohort Spacio-Temporal of human mobility
          and preferences/urban Geo-Social connectivity{" "}
          {filteredScreens?.length}
        </p>
      </div>
      {loadingFinalSummary ? (
        <Loading />
      ) : errorFinalSummary ? (
        <Message message="ERROR MESSAGE" content={errorFinalSummary} />
      ) : (
        <div className="py-5">
          <FinalSummaryTable
            data={finalSummaryData}
            allScreen={allScreens}
            handleSetFilteredScreens={handleSetFilteredScreen}
            handleMultipleFilteredScreen={handleMultipleFilteredScreen}
            isScreenSelected={isScreenSelected}
          />
        </div>
      )}

      {showFinalResults ? (
        <div>
          {loadingPricing ? (
            <Loading />
          ) : (
            <div className="py-5">
              <NewFinalResultTable
                data={pricingData}
                setTotalCost={(value: number) => {
                  setTotalCost(value);
                  setNetTotal(value);
                }}
                isStateShow={true}
                setSumData={setSumData}
                campaignType={location.state.campaignDetails.campaignType}
                startDate={location.state.campaignDetails.startDate}
                endDate={location.state.campaignDetails.endDate}
              />
            </div>
          )}

          <div className="py-5">
            <button
              type="button"
              title="button"
              className="m-2 w-40 h-10 bg-[#789EFF] focus:outline-none hover:bg-gray-300 rounded-md"
              onClick={() => {
                handleClickGetEstimate();
                setIsOpenModel(true);
              }}
            >
              <h1 className="text-sm text-white font-semibold antialiased">
                View Plan Pics
              </h1>
            </button>
          </div>
          <div className="w-full">
            <div className="grid grid-cols-12">
              <div className="col-span-8">
                <h1 className="text-[#006BE8] text-base font-bold">
                  Add trigger based campaign with additional 1% of your current
                  campaign budget i.e. INR {Number(totalCost * 0.1).toFixed(0)}
                </h1>
                <p className="text-[#AF0D0D] text-sm font-semibold">
                  Note:- The creative of such campaigns shall be contextual. On
                  occuranvce of a situation, trigger, normal ads will also carry
                  trigger based on content given by the client
                </p>
              </div>
              <div className="col-span-4 flex justify-end items-center">
                <button
                  type="button"
                  title="button"
                  className="m-2 w-44 bg-[#0041C1] focus:outline-none hover:bg-gray-300 rounded-md"
                >
                  <div className="p-2 flex flex-row">
                    <div className="basis-3/4 px-1">
                      <h1 className="text-sm text-white font-semibold antialiased">
                        What are trigger based campaigns
                      </h1>
                    </div>
                    <div className="basis-1/4 flex justify-center items-center">
                      <FaQuestion color="white" size="28px" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-end py-2">
          <button
            type="button"
            title="button"
            className="flex justify-end items-center text-blue-500"
            // className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
            onClick={() => {
              handleClickGetEstimate();
              setShowFinalResults(true);
            }}
          >
            Click here to see your cost and continue your planning {`>`}
          </button>
        </div>
      )}

      {showFinalResults && showTriggers ? (
        <div className="pt-4">
          <Radio.Group onChange={handleSelectTrigger} value={selectedTrigger}>
            <Radio value={"Weather trigger"}>Weather trigger</Radio>
            <Radio value={"Sports trigger"}>Sports trigger</Radio>
          </Radio.Group>
          {selectedTrigger === "Weather trigger" ? (
            <div className="py-5">
              <h1 className="text-2xl font-bold py-5">
                A. Condition to weather situations
              </h1>
              <div className="border border-phroohGray">
                <div className="grid grid-cols-12">
                  <div className="col-span-8 p-5 border border-phroohGray">
                    <div className="flex flex-row items-center">
                      <div className="basis-3/4 flex flex-row items-center">
                        <img
                          src={sun1}
                          alt="sun"
                          className="flex justify-center items-center p-2 w-12"
                        />
                        <h1 className="px-2 text-sm font-bold">
                          Play my ad when heat is
                        </h1>
                        <input
                          title="as"
                          id="as"
                          className="border w-12 h-8 m-1"
                          value={temperature}
                          onChange={(e) => setTemperature(e.target.value)}
                        />
                        {/* <h1 className="px-2 text-sm font-bold">
                        {" "}
                        and occupy upto
                      </h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 p-5 border border-phroohGray flex flex-row justify-between items-center">
                    <div className="basis-1/2 relative px-2 w-32">
                      <input
                        type="number"
                        id="Length"
                        disabled
                        // defaultValue="15"
                        className="peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent h-8 w-12 px-2 text-base text-gray-900 focus:border-2 focus:border-blue-600 focus:outline-none focus:ring-0"
                        value={Number(totalCost * 0.1).toFixed(0)}
                      />
                      <label
                        htmlFor="Length"
                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600"
                      >
                        cost
                      </label>
                      <div className="absolute mr-2 right-1 top-1/2 -translate-y-1/2 bg-white text-gray-300 peer-placeholder-shown:text-white peer-focus:text-gray-500">
                        INR
                      </div>
                    </div>

                    <div className="basis-1/4 pl-2">
                      <button
                        type="button"
                        title="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                        onClick={() => {
                          addWeatherTriggers({
                            triggerType: TEMPERATURE_GREATER_THAN,
                            value: temperature,
                          });
                        }}
                      >
                        <h1 className="text-base text-white">Save</h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      {isAddedWeatherTrigger(TEMPERATURE_GREATER_THAN) ? (
                        <RiDeleteBin5Fill
                          title="remove"
                          color="red"
                          size="24px"
                          onClick={() =>
                            weatherTriggerRemove({
                              triggerType: TEMPERATURE_GREATER_THAN,
                              value: temperature,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-8 p-5 border border-phroohGray">
                    <div className="flex flex-row items-center">
                      <div className="basis-3/4 flex flex-row items-center">
                        <img
                          src={rain1}
                          alt="sun"
                          className="flex justify-center items-center p-2 w-12"
                        />
                        <h1 className="px-2 text-sm font-bold">
                          Play my ad as per rain forecast
                        </h1>
                        <select
                          className="border w-16 h-8"
                          onChange={(e) => setRainForCast(e.target.value)}
                        >
                          <option value="Y" label="Yes" />
                          <option value="N" label="No" />
                        </select>
                        {/* <h1 className="px-2 text-sm font-bold">
                        {" "}
                        and occupy upto
                      </h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 p-5 border border-phroohGray flex flex-row justify-between items-center">
                    <div className="basis-1/2 relative px-2 w-32">
                      <input
                        type="number"
                        id="Length"
                        disabled
                        defaultValue="15"
                        className="peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent h-8 w-12 px-2 text-base text-gray-900 focus:border-2 focus:border-blue-600 focus:outline-none focus:ring-0"
                        value={Number(totalCost * 0.1).toFixed(0)}
                      />
                      <label
                        htmlFor="Length"
                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600"
                      >
                        cost
                      </label>
                      <div className="absolute mr-2 right-1 top-1/2 -translate-y-1/2 bg-white text-gray-300 peer-placeholder-shown:text-white peer-focus:text-gray-500">
                        INR
                      </div>
                    </div>
                    <div className="basis-1/4 pl-2">
                      <button
                        type="button"
                        title="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                        onClick={() => {
                          addWeatherTriggers({
                            triggerType: RAIN_FORECAST,
                            value: rainForCast,
                          });
                        }}
                      >
                        <h1 className="text-base text-white">Save</h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      {isAddedWeatherTrigger(RAIN_FORECAST) ? (
                        <RiDeleteBin5Fill
                          title="remove"
                          color="red"
                          size="24px"
                          onClick={() =>
                            weatherTriggerRemove({
                              triggerType: RAIN_FORECAST,
                              value: rainForCast,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-8 p-5 border border-phroohGray">
                    <div className="flex flex-row items-center">
                      <div className="basis-3/4 flex flex-row items-center">
                        <img
                          src={cloud1}
                          alt="sun"
                          className="flex justify-center items-center p-2 w-12"
                        />
                        <h1 className="px-2 text-sm font-bold">
                          Play my ad as per wind speed forecast
                        </h1>
                        <select
                          className="border w-16 h-8"
                          onChange={(e) => setWindSpeedType(e.target.value)}
                        >
                          <option value="G" label="greater Then" />
                          <option value="L" label="Less Then" />
                        </select>
                        <input
                          title="as"
                          id="as"
                          className="border w-12 h-8 m-1"
                          onChange={(e) => setWindSpeed(e.target.value)}
                        />
                        {/* <h1 className="px-2 text-sm font-bold">
                        {" "}
                        and occupy upto
                      </h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 p-5 border border-phroohGray flex flex-row justify-between items-center">
                    <div className="basis-1/2 relative px-2 w-32">
                      <input
                        type="number"
                        id="Length"
                        disabled
                        defaultValue="15"
                        className="peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent h-8 w-12 px-2 text-base text-gray-900 focus:border-2 focus:border-blue-600 focus:outline-none focus:ring-0"
                        value={Number(totalCost * 0.1).toFixed(0)}
                      />
                      <label
                        htmlFor="Length"
                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600"
                      >
                        cost
                      </label>
                      <div className="absolute mr-2 right-1 top-1/2 -translate-y-1/2 bg-white text-gray-300 peer-placeholder-shown:text-white peer-focus:text-gray-500">
                        INR
                      </div>
                    </div>
                    <div className="basis-1/4 pl-2">
                      <button
                        type="button"
                        title="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                        onClick={() => {
                          if (windSpeedType === "G")
                            addWeatherTriggers({
                              triggerType: WIND_SPEED_GREATER_THAN,
                              value: windSpeed,
                            });
                          else {
                            addWeatherTriggers({
                              triggerType: WIND_SPEED_LESS_THAN,
                              value: windSpeed,
                            });
                          }
                        }}
                      >
                        <h1 className="text-base text-white">Save</h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      {isAddedWeatherTrigger(
                        windSpeedType === "G"
                          ? WIND_SPEED_GREATER_THAN
                          : WIND_SPEED_LESS_THAN
                      ) ? (
                        <RiDeleteBin5Fill
                          title="remove"
                          color="red"
                          size="24px"
                          onClick={() =>
                            weatherTriggerRemove({
                              triggerType:
                                windSpeedType === "G"
                                  ? WIND_SPEED_GREATER_THAN
                                  : WIND_SPEED_LESS_THAN,
                              value: temperature,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12">
                  <div className="col-span-8 p-5 border border-phroohGray">
                    <div className="flex flex-row items-center">
                      <div className="basis-3/4 flex flex-row items-center">
                        <img
                          src={icecube1}
                          alt="sun"
                          className="flex justify-center items-center p-2 w-12"
                        />
                        <h1 className="px-2 text-sm font-bold">
                          Play my ad when cold is
                        </h1>
                        <input
                          title="as"
                          id="as"
                          className="border w-12 h-8 m-1"
                          value={coldTemperature}
                          onChange={(e) => setColdTemperature(e.target.value)}
                        />
                        {/* <h1 className="px-2 text-sm font-bold">
                        {" "}
                        and occupy upto
                      </h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 p-5 border border-phroohGray flex flex-row justify-between items-center">
                    <div className="basis-1/2 relative px-2 w-32">
                      <input
                        type="number"
                        id="Length"
                        disabled
                        defaultValue="15"
                        className="peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent h-8 w-12 px-2 text-base text-gray-900 focus:border-2 focus:border-blue-600 focus:outline-none focus:ring-0"
                        value={Number(totalCost * 0.1).toFixed(0)}
                      />
                      <label
                        htmlFor="Length"
                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600"
                      >
                        cost
                      </label>
                      <div className="absolute mr-2 right-1 top-1/2 -translate-y-1/2 bg-white text-gray-300 peer-placeholder-shown:text-white peer-focus:text-gray-500">
                        INR
                      </div>
                    </div>
                    <div className="basis-1/4 pl-2">
                      <button
                        type="button"
                        title="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                        onClick={() => {
                          addWeatherTriggers({
                            triggerType: TEMPERATURE_LESS_THAN,
                            value: coldTemperature,
                          });
                        }}
                      >
                        <h1 className="text-base text-white">Save</h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      {isAddedWeatherTrigger(TEMPERATURE_LESS_THAN) ? (
                        <RiDeleteBin5Fill
                          title="remove"
                          color="red"
                          size="24px"
                          onClick={() =>
                            weatherTriggerRemove({
                              triggerType: TEMPERATURE_LESS_THAN,
                              value: coldTemperature,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-12">
                  <div className="col-span-8 p-5 border border-phroohGray">
                    <div className="flex flex-row items-center">
                      <div className="basis-3/4 flex flex-row items-center">
                        <img
                          src={speedometer1}
                          alt="sun"
                          className="flex justify-center items-center p-2 w-12"
                        />
                        <h1 className="px-2 text-sm font-bold">
                          Play my ad when AQI is
                        </h1>
                        <select
                          className="border w-16 h-8"
                          onChange={(e) => setAirQualityType(e.target.value)}
                        >
                          <option value="G" label="greater Then" />
                          <option value="L" label="Less Then" />
                        </select>
                        <input
                          title="as"
                          id="as"
                          type="number"
                          className="border w-12 h-8 m-1"
                          value={airQuality}
                          onChange={(e) => setAirQuality(e.target.value)}
                        />
                        {/* <h1 className="px-2 text-sm font-bold">
                        {" "}
                        and occupy upto
                      </h1> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 p-5 border border-phroohGray flex flex-row justify-between items-center">
                    <div className="basis-1/2 relative px-2 w-32">
                      <input
                        type="number"
                        id="Length"
                        disabled
                        defaultValue="15"
                        className="peer block w-full appearance-none rounded-lg border border-gray-300 bg-transparent h-8 w-12 px-2 text-base text-gray-900 focus:border-2 focus:border-blue-600 focus:outline-none focus:ring-0"
                        value={Number(totalCost * 0.1).toFixed(0)}
                      />
                      <label
                        htmlFor="Length"
                        className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-blue-600"
                      >
                        cost
                      </label>
                      <div className="absolute mr-2 right-1 top-1/2 -translate-y-1/2 bg-white text-gray-300 peer-placeholder-shown:text-white peer-focus:text-gray-500">
                        INR
                      </div>
                    </div>
                    <div className="basis-1/4 pl-2">
                      <button
                        type="button"
                        title="button"
                        className="p-1 w-20 bg-[#1C9EDA] focus:outline-none hover:bg-gray-300 rounded-md"
                        onClick={() => {
                          if (airQualityType === "G")
                            addWeatherTriggers({
                              triggerType: AIR_QUALITY_GREATER_THAN,
                              value: airQuality,
                            });
                          else {
                            addWeatherTriggers({
                              triggerType: AIR_QUALITY_LESS_THAN,
                              value: airQuality,
                            });
                          }
                        }}
                      >
                        <h1 className="text-base text-white">Save</h1>
                      </button>
                    </div>
                    <div className="basis-1/4 flex justify-center">
                      {isAddedWeatherTrigger(
                        airQuality === "G"
                          ? AIR_QUALITY_GREATER_THAN
                          : AIR_QUALITY_LESS_THAN
                      ) ? (
                        <RiDeleteBin5Fill
                          title="remove"
                          color="red"
                          size="24px"
                          onClick={() =>
                            weatherTriggerRemove({
                              triggerType:
                                airQuality === "G"
                                  ? AIR_QUALITY_GREATER_THAN
                                  : AIR_QUALITY_LESS_THAN,
                              value: temperature,
                            })
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CricketTrigger
              totalCost={totalCost}
              setAdditionalCostForCricket={setAdditionalCostForCricket}
              additionalCost={additionalCost}
            />
          )}

          <div className="py-5">
            <h1 className="py-2">
              <strong>Final Budget Plan-</strong>
            </h1>
            <div className="py-1">
              <NewFinalResultTable
                data={pricingData}
                setTotalCost={(value: number) => {
                  setTotalCost(value);
                  setNetTotal(value);
                }}
                isStateShow={false}
                setSumData={setSumData}
                campaignType={location.state.campaignDetails.campaignType}
                startDate={location.state.campaignDetails.startDate}
                endDate={location.state.campaignDetails.endDate}
              />
            </div>
            {/* <div className="grid grid-cols-3">
              <div className="p-1 border flex justify-center">
                <h1 className="font-bold">Estimated Impressions</h1>
              </div>
              <div className="p-1 border flex justify-center">
                <h1>
                  {parseInt(
                    campaignType == CAMPAIGN_TYPE_REGULAR
                      ? sumData?.impressionPerDay
                      : sumData?.impressionPerDayCohort
                  )}
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="p-1 border flex justify-center">
                <h1 className="font-bold">Total Budget</h1>
              </div>
              <div className="p-1 border flex justify-center">
                <h1>{netTotal || 0}</h1>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="p-1 border flex justify-center">
                <h1 className="font-bold">Total Days</h1>
              </div>
              <div className="p-1 border flex justify-center">
                <h1>
                  {getNumberOfDaysBetweenTwoDates(
                    (getDataFromLocalStorage(CAMPAIGN_DETAILS) || {})
                      ?.startData,
                    (getDataFromLocalStorage(CAMPAIGN_DETAILS) || {})?.endDate
                  )}
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="p-1 border flex justify-center">
                <h1 className="font-bold">Total Slot</h1>
              </div>
              <div className="p-1 border flex justify-center">
                <h1>
                  {campaignType == CAMPAIGN_TYPE_REGULAR
                    ? sumData?.slotPerDay
                    : sumData?.slotPerDayCohort}
                </h1>
              </div>
            </div>
            <div className="grid grid-cols-3">
              <div className="p-1 border flex justify-center">
                <h1 className="font-bold">CPM</h1>
              </div>
              <div className="p-1 border flex justify-center">
                <h1>
                  {parseInt(
                    campaignType == CAMPAIGN_TYPE_REGULAR
                      ? sumData?.cpm
                      : sumData?.cpmCohort
                  )}
                </h1>
              </div>
            </div> */}
            <button
              type="button"
              title="button"
              className="mt-5 px-2 w-36 py-3 bg-[#2CAE77] border focus:outline-none hover:bg-gray-300 rounded-3xl"
            >
              <div className="flex gap-3 justify-center items-center">
                <h1 className="text-sm text-white font-semibold">
                  Download File
                </h1>
                <MdOutlineKeyboardArrowDown color="white" size="1rem" />
              </div>
            </button>
          </div>
        </div>
      ) : showFinalResults && !showTriggers ? (
        <div className="w-full flex justify-end py-2">
          <button
            type="button"
            title="button"
            className="flex justify-end items-center text-blue-500"
            // className="w-32 h-9 border border-solid px-2 bg-proohYellow rounded-md text-white hover:bg-sky-700"
            onClick={() => {
              setShowTriggers(true);
            }}
          >
            Click here to add additional triggers for your campaigns {`>`}
          </button>
        </div>
      ) : null}
      <div className="py-5 flex flex-row gap-5">
        <button
          type="button"
          title="button"
          className="px-2 w-28 py-3 bg-white border focus:outline-none hover:bg-gray-300 rounded-md"
          onClick={() => {
            handleClickGetEstimate();
            // navigate("/create-campaign/upload-creatives");
          }}
        >
          <h1 className="text-sm font-bold">Save And Exit</h1>
        </button>
        <button
          type="button"
          title="button"
          className="px-2 w-36 py-3 bg-proohYellow focus:outline-none hover:bg-gray-300 rounded-md"
          onClick={() => {
            handleClickGetEstimate();
            navigate("/create-campaign/upload-creatives");
          }}
        >
          <h1 className="text-sm text-black font-bold">Get Creative Details</h1>
        </button>
      </div>
    </div>
  );
};
