import { Tabs } from "antd";
import { FaPause, FaPlay } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import {
  CAMPAIGN_STATUS_ACTIVE,
  CAMPAIGN_STATUS_DELETED,
  CAMPAIGN_STATUS_PAUSE,
} from "../../../constants/campaignConstants";
import {
  ActiveCampaignsTable,
  PendingCampaignsTable,
  CompletedCampaignsTale,
  PauseCampaignsTable,
} from "../tables";

export const CampaignsTabs = (props: any) => {
  const {
    setSelectedCampaigns,
    handleChangeStatus,
    selectedCampaigns,
    screenName,
    screen,
    handleSetPriorityByOneClick,
  } = props;

  const items = [
    {
      label: `Active campaign`,
      key: "1",
      children: (
        <ActiveCampaignsTable
          status={"Active"}
          screen={screen}
          setSelectedCampaigns={setSelectedCampaigns}
          selectedCampaigns={selectedCampaigns}
          screenName={screenName}
          handleSetPriorityByOneClick={handleSetPriorityByOneClick}
          items={[
            {
              key: "1",
              label: (
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleChangeStatus(CAMPAIGN_STATUS_PAUSE)}
                >
                  <FaPause title="pause campaign" />
                  <h1>Pause Campaigns</h1>
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleChangeStatus(CAMPAIGN_STATUS_DELETED)}
                >
                  <RiDeleteBin5Fill title="delete campaign" color="red" />
                  <h1>Delete Campaigns</h1>
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      label: `Pause campaign`,
      key: "2",
      children: (
        <PauseCampaignsTable
          status={"Pause"}
          screen={screen}
          setSelectedCampaigns={setSelectedCampaigns}
          selectedCampaigns={selectedCampaigns}
          screenName={screenName}
          items={[
            {
              key: "1",
              label: (
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleChangeStatus(CAMPAIGN_STATUS_DELETED)}
                >
                  <RiDeleteBin5Fill title="delete campaign" color="red" />
                  <h1>Delete Campaigns</h1>
                </div>
              ),
            },
            {
              key: "2",
              label: (
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleChangeStatus(CAMPAIGN_STATUS_ACTIVE)}
                >
                  <FaPlay title="activate campaign" color="green" />
                  <h1>Activate again </h1>
                </div>
              ),
            },
          ]}
        />
      ),
    },
    {
      label: `Pending Campaigns`,
      key: "3",
      children: (
        <PendingCampaignsTable
          screen={screen}
          status={"Pause"}
          screenName={screenName}
        />
      ),
    },

    {
      label: `Completed Campaigns`,
      key: "4",
      children: (
        <CompletedCampaignsTale
          screen={screen}
          status={"Completed"}
          screenName={screenName}
          setSelectedCampaigns={setSelectedCampaigns}
          selectedCampaigns={selectedCampaigns}
          items={[
            {
              key: "1",
              label: (
                <div
                  className="flex gap-2 items-center"
                  onClick={() => handleChangeStatus(CAMPAIGN_STATUS_ACTIVE)}
                >
                  <FaPlay title="activate campaign" color="green" />
                  <h1>Activate again </h1>
                </div>
              ),
            },
          ]}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" type="card" size={"large"} items={items} />;
};
