import { Select } from "antd";
import { CampaignLogReport } from "../../components";
import { convertIntoDateAndTime } from "../../utils/dateAndTimeUtils";
import { useState } from "react";

export const CampaignInfo = ({ campaign, options }: any) => {
  const [myCampaign, setMyCampaign] = useState<any>("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  return (
    <div className="flex gap-8">
      <div className="font-bold">
        <h1>Campaign Name</h1>
        <h1>Start Date</h1>
        <h1>End Date</h1>
        <h1>Total Campaigns</h1>
        <h1>Campaign Type</h1>
        <h1>Campaign Booked for</h1>
      </div>
      <div>
        <h1>{campaign?.name}</h1>
        <h1>{convertIntoDateAndTime(campaign?.startDate) || "Default"}</h1>
        <h1>{convertIntoDateAndTime(campaign?.endDate) || "Default"}</h1>
        <h1>{campaign?.campaigns?.length}</h1>
        <h1>{campaign?.campaignType}</h1>
        <h1>{campaign?.campaignBookedForDays || "Infinite"} Days</h1>
      </div>

      <div className="flex flex-col">
        <h1 className="pb-2">Select campaign to download log report</h1>
        <Select
          showSearch
          placeholder="Please select screen"
          options={options}
          onChange={setMyCampaign}
          value={myCampaign}
          size="large"
          style={{ width: "300px" }}
        />
        <div className="flex gap-4 pt-2">
          <div className="flex flex-col gap-1">
            <h1>Start Date</h1>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              placeholder="Start Date"
              className="border border-1 py-2 px-4"
            />
          </div>
          <div className="flex flex-col gap-1">
            <h1>End Date</h1>
            <input
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              placeholder="End Date"
              className="border border-1 py-2 px-4"
            />
          </div>
        </div>

        <CampaignLogReport
          campaign={myCampaign}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
};
